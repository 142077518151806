import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import axios from "axios";
import Pusher from "pusher-js";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    loading: false,
    authenticated: false,
    connected: false,

    access_token: "",
    refresh_token: "",

    user: {},
    tickets: [],
    parties: [],
  },
  mutations: {
    setAuthenticated(state) {
      state.authenticated = true;
    },
    unsetAuthenticated(state) {
      state.authenticated = false;
      state.user = {};
    },
    setLoading(state) {
      state.loading = true;
    },
    unsetLoading(state) {
      state.loading = false;
    },
    setConnected(state) {
      state.connected = true;
    },
    unsetConnected(state) {
      state.connected = false;
    },
    logout(state) {
      state.authenticated = false;
      state.connected = false;
      state.user = {};
      state.tickets = [];
      state.parties = [];
      state.access_token = "";
      state.refresh_token = "";
    },
    updateParties(state, data) {
      state.parties = data.parties;
    },
    removeParties(state) {
      state.parties = [];
    },
    updateTickets(state, data) {
      state.tickets = data.tickets;
    },
    removeTickets(state) {
      state.tickets = [];
    },
    updateTokens(state, data) {
      state.access_token = data.access;
      state.refresh_token = data.refresh;
    },
    removeTokens(state) {
      state.access_token = null;
      state.refresh_token = null;
    },
    updateUser(state, data) {
      state.user = data.user;
    },
    removeUser(state) {
      state.user = null;
    },
  },
  actions: {
    getUser(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_USER_URL)
          .then((response) => {
            console.log(response);
            context.commit("updateUser", { user: response.data });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    login(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_AUTH_LOGIN_URL, {
            email: data.email,
            password: data.password,
          })
          .then((response) => {
            context.commit("setAuthenticated");
            context.commit("updateTokens", {
              refresh: response.data.refresh,
              access: response.data.access,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    connect(context) {
      //Left off building the connected feature only in data store. still have to add it to the components, and want to make sure I understand the promise reject and response on subscription succeeded or not.
      return new Promise((resolve, reject) => {
        //Pushing logging --> Do not include in production
        Pusher.logToConsole = true;

        var pusher = new Pusher("8efe2a56c26d0e5bcae3", {
          authEndpoint: process.env.VUE_APP_AUTH_REALTIME_URL,
          cluster: "mt1",
          auth: {
            headers: {
              Authorization: "Bearer " + context.state.access_token,
            },
          },
          forceTLS: true,
        });

        var channel_name = "private-staff-" + context.state.user.vendor_uuid;
        var channel = pusher.subscribe(channel_name);

        channel.bind("pusher:subscription_succeeded", function(data) {
          context.commit("setConnected");
          resolve(data);
        });

        channel.bind("pusher:subscription_error", function(data) {
          context.commit("unsetConnected");
          reject(data);
        });

        pusher.connection.bind("connected", function() {
          context.commit("setConnected");
        });

        pusher.connection.bind("error", function() {
          context.commit("unsetConnected");
        });
      });
    },
    getParties(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_PARTIES_URL)
          .then((response) => {
            context.commit("updateParties", { parties: response.data });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addParty(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_CREATE_PARTY_URL, {
            table: data.table,
            last_name: data.last_name,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    confirmDelivery(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_DELIVERED_ITEM_URL, {
            ticket_id: data.ticket_id,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    closeOrder(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_CLOSE_ORDER_URL, {
            order_id: data.order_id,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTickets(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_TICKETS_URL)
          .then((response) => {
            context.commit("updateTickets", { tickets: response.data });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  plugins: [vuexLocalStorage.plugin],
});
