import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import AxiosInterceptor from "./interceptors";

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import axios from "axios";
import VueAxios from "vue-axios";

import VueLodash from "vue-lodash";
import lodash from "lodash";

Vue.use(require("vue-moment"));

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(VueLodash, { lodash: lodash });

AxiosInterceptor();

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount("#app");
