import axios from "axios";

import router from "../router";

export default function AxiosInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      if (router.app.$store.state.authenticated) {
        config.headers["Authorization"] =
          "Bearer " + router.app.$store.state.access_token;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      } else if (router.app.$store.state.authenticated) {
        axios.interceptors.response.eject(interceptor);

        return axios
          .post(process.env.VUE_APP_AUTH_REFRESH_URL, {
            refresh: router.app.$store.state.refresh_token,
          })
          .then((response) => {
            router.app.$store.commit("updateTokens", {
              refresh: response.data.refresh,
              access: response.data.access,
            });
            error.response.config.headers["Authorization"] =
              "Bearer " + response.data.access;
            return axios(error.response.config);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              router.app.$store.commit("unsetAuthenticated");
              router.app.$store.commit("removeTokens");
              router.push({ name: "Login" });
            }
            return Promise.reject(error);
          })
          .finally(AxiosInterceptor);
      } else {
        return Promise.reject(error);
      }
    }
  );
}
