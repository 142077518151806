<template>
  <!-- Footer -->
  <b-container fluid>
    <b-row no-gutters>
      <b-col>
        <footer class="footer">
          <p>
            <a
              target="_blank"
              style="color:black;"
              href="https://legal.tblside.com/privacy/"
              >Privacy Policy</a
            >
            |
            <a
              target="_blank"
              style="color:black;"
              href="https://legal.tblside.com/restaurant/terms-of-use/"
              >Terms of Use</a
            >
            | tblside technologies inc. &copy; 2020 - 2021
          </p>
        </footer>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>
