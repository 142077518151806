<template>
  <div id="app">
    <Loading></Loading>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";

import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Loading,
    Footer,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import "./assets/css/style.css";
:root {
  --main-color: #410093;
}
</style>
