import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Roles",
    component: () => import("../views/Roles.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/login/",
    name: "Login",
    component: () => import("../views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.authenticated) {
        console.log("worked");
        next({ name: "Roles" });
      } else {
        next();
      }
    }
  },
  {
    path: "/roles/fulfiller/",
    name: "Fulfiller",
    component: () => import("../views/Fulfiller.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/roles/runner/",
    name: "Runner",
    component: () => import("../views/Runner.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/roles/owner/",
    name: "Owner",
    component: () => import("../views/Owner.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/logout/",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      if (store.state.authenticated) {
        store.commit("logout");
        next({ name: "Login" });
      } else {
        next({ name: "Login" });
      }
    }
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      requireAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  console.log(to.name);
  if (to.meta.requireAuth && to.name != "Authenticate") {
    if (store.state.authenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
